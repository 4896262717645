window.$ = window.jQuery = require('jquery');
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/brands';
import "slick-carousel";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

jQuery(document).ready(function($) {
	var windowWidth = $(window).width()

/* ====================== MOBILE NAV ======================  */
    $('.menu-toggle').on("click", function() {
        $(this).toggleClass('active');

        if($(this).hasClass('active')) {
        	$(this).text('CLOSE');
        } else {
        	$(this).text('MENU');
        }
        $('header nav').toggleClass('open');
        $('header').toggleClass('open');
        $('body').toggleClass('noscroll');
    })

    if(windowWidth < 1024) {
	    $('#menu-main-menu .menu-item-has-children').on("click", function() {
	       $(this).toggleClass('expanded');
	       $(this).find('.sub-menu').slideToggle();
	    })
	}

/* ====================== Semi Sticky Nav ======================  */
	// var prevScrollpos = window.pageYOffset;
	// var currentScrollPos = window.pageYOffset;

	// if(currentScrollPos > 120) {
	// 	$("header").addClass("headerscrolled");
	// }

	// window.onscroll = function() {
	// 	var currentScrollPos = window.pageYOffset;

	// 	if ((prevScrollpos > currentScrollPos)) {
	// 		$('header').addClass('show');
	// 	} else if((prevScrollpos < currentScrollPos)){
	// 		$('header').removeClass('show');
	// 	}
	// 	else {
	// 		$('header').removeClass('show');
	// 	}

	// 	if(currentScrollPos > 120) {
	// 		$("header").addClass("headerscrolled");
	// 	} else {
	// 		$("header").removeClass("headerscrolled show");
	// 	}

	// 	prevScrollpos = currentScrollPos;
	// }

/* ====================== Submit Gravity Form ======================  */
	jQuery(document).on('gform_post_render', function(){
	     $('.gform_wrapper .btn').on('click', function() {
        	$(this).parents('.gform_wrapper').find('input[type=submit]').click();
        });
	});

/* ====================== FAQs Reveal ======================  */
	$('div.show-more').on("click", function() {
		$(this).toggleClass('active');
		$('.hidden-faqs').slideToggle();

		if($(this).hasClass('active')) {
			$('.show-more').html('View Less');
		} else {
			$('.show-more').html('View More');
		}
	})

/* ====================== Single Property Masthead ======================  */
	$('.homepage-masthead .bg-carousel').slick({
		fade: true,
		dots: false,
		arrows: false,
		infinite: true,
		slidesToShow: 1,
		speed: 800,
		autoplay: true,
		autoplaySpeed: 5000,
		rows: 0,
	});

/* ====================== Single Property Masthead ======================  */
	$('.single-property-masthead .carousel').slick({
		fade: false,
		dots: false,
		arrows: true,
		infinite: true,
		slidesToShow: 1,
		speed: 800,
		autoplay: false,
		autoplaySpeed: 5000,
		mobileFirst: true,
		rows: 0,
		responsive: [
			{
			breakpoint: 768,
			settings: {
				slidesToShow: 2,
			}
			}
    	]
	});

	// $('.single-property-masthead .carousel').on('beforeChange', function(event, slick, currentSlide, nextSlide){
	// 	if(currentSlide > nextSlide) {
	// 		$('.carousel-2').slick('slickPrev');
	// 		$('.carousel-3').slick('slickPrev');
	// 	} else {
	// 		$('.carousel-2').slick('slickNext');
	// 		$('.carousel-3').slick('slickNext');
	// 	}
	// });


	// $('.single-property-masthead .carousel-2').slick({
	// 	fade: true,
	// 	dots: false,
	// 	arrows: false,
	// 	infinite: true,
	// 	slidesToShow: 1,
	// 	speed: 800,
	// 	autoplay: false,
	// 	autoplaySpeed: 5000,
	// 	rows: 0,
	// 	initialSlide: 1,
	// 	draggable: false,
	// 	touchMove: false,
	// 	swipeToSlide: false,
	// 	swipe: false,
	// });

	// $('.single-property-masthead .carousel-3').slick({
	// 	fade: true,
	// 	dots: false,
	// 	arrows: false,
	// 	infinite: true,
	// 	slidesToShow: 1,
	// 	speed: 800,
	// 	autoplay: false,
	// 	autoplaySpeed: 5000,
	// 	rows: 0,
	// 	initialSlide: 2,
	// 	draggable: false,
	// 	touchMove: false,
	// 	swipeToSlide: false,
	// 	swipe: false,
	// });

/* ====================== DRAG AND SCROLL Locations ======================  */
const slider = document.querySelector('.locations');
let isDown = false;
let startX;
let scrollLeft;

if($(slider).length > 0) {
	slider.addEventListener('mousedown', (e) => {
	  isDown = true;
	  slider.classList.add('active');
	  startX = e.pageX - slider.offsetLeft;
	  scrollLeft = slider.scrollLeft;
	});
	slider.addEventListener('mouseleave', () => {
	  isDown = false;
	  slider.classList.remove('active');
	});
	slider.addEventListener('mouseup', () => {
	  isDown = false;
	  slider.classList.remove('active');
	});
	slider.addEventListener('mousemove', (e) => {
	  if(!isDown) return;
	  e.preventDefault();
	  const x = e.pageX - slider.offsetLeft;
	  const walk = (x - startX) * 1; //scroll-fast
	  slider.scrollLeft = scrollLeft - walk;
	});
}

/* ====================== COPY URL TO CLIPBOARD ======================  */
	$('.social-share-link-url').on("click", function() {
		var dummy = document.createElement('input'),
		text = window.location.href;

		document.body.appendChild(dummy);
		dummy.value = text;
		dummy.select();
		document.execCommand('copy');
		document.body.removeChild(dummy);

		$(this).addClass('copied');

		setTimeout(
		function() {
			$('.social-share-link-url').removeClass('copied');
		}, 2000);
	});

/* ====================== Init Fancybox ======================  */
	Fancybox.bind("[data-fancybox]", {});

/* ====================== Price toggle ======================  */
    $('body').on( 'click', '.price-toggle', function( e ){
    	e.preventDefault();
        $('.price-toggle .toggle').toggleClass('active');
       	var currency = $('.price-toggle .toggle.active').attr('currency-related');
       	$('.price').attr('class', ('price ' +currency+'-toggle'));
       	sessionStorage.setItem('currencySelected', currency);

       	if(currency == 'dollar'){
       		alert('Please note, all base prices are in Euros. Converted prices are just an indication and may not be 100% accurate. Please contact us for more information');
       	}
    });

    // Setting Session Price
    let currencySelected = sessionStorage.getItem('currencySelected');
    if(currencySelected == 'pound') {
    	$('.price-toggle .toggle').removeClass('active')
		$('.price-toggle .toggle[currency-related=' + currencySelected + ']').addClass('active');
		$('.price').attr('class', ('price ' +currencySelected+'-toggle'));
    }

/* ====================== Accordion ======================  */
	//$('.accordion .accordion-row:first-of-type .accordion-row-intro').addClass('active');
	//$('.accordion .accordion-row:first-of-type .accordion-row-content').slideDown();
	//$('.accordion .accordion-row:first-of-type .accordion-row-content').addClass('active');

	$('.faqs .accordion-row-intro').on('click', function () {
		if($(this).hasClass('active')) {
			$(this).removeClass('active');
			$(this).next('.accordion-row-content').slideUp();
			$(this).next('.accordion-row-content').removeClass('active');
		} else {
			$('.accordion .accordion-row-intro').removeClass('active');
			$('.accordion-row-content').slideUp();
			$('.accordion-row-content').removeClass('active');

			$(this).addClass('active');
			$(this).next('.accordion-row-content').slideDown();
			$(this).next('.accordion-row-content').addClass('active');
		}
    });

/* ====================== ACCOUNT TABS ======================  */
    $('.sidebar .tab').on("click", function() {
    	$('.sidebar .tab').removeClass('active');
    	$('.tab-content').removeClass('active');
       	var tabRelated = $(this).attr('tab-related');
       	$(this).addClass('active');
       	$('.tab-content[tab-related='+ tabRelated +']').addClass('active');
    });

/* ====================== Dropdown Filtering ======================  */
	$('#region').change(function(){
		if($(this).val() != '') {
			$('.province-container').attr('disabled', false);

			var province = $(this).val();
			province = province.replace(/\s+/g, '-').toLowerCase();

			$('.province-select').hide().attr('disabled', true);
			$('.province-select[region-related="' + province + '"]').show().attr('disabled', false);
		} else {
			$('.province-container, .municipality-container').attr('disabled', true);
		}
	});


/* ====================== GSAP SAMPLE ANIMATIONS ======================  */
	ScrollTrigger.matchMedia({
		'(min-width:1024px)':function(){
			let parallax = gsap.utils.toArray('.homepage-masthead, .inner-masthead')
			parallax.forEach((item, index) => {
				let tl = gsap.timeline({
				scrollTrigger: {
				trigger: item,
				scrub: true,
				start:"90% 400px",
				toggleActions:"restart none none reset",
				end: "100%",
				markers: false
				}
				});
				tl.to(item, {
					opacity: 0,
					duration:5,
				});
			});
		}
	});

	var triggerPosition = '"top 90%"';
	ScrollTrigger.matchMedia({
		'(max-width:767px)':function(){
			triggerPosition = '"top 85%"';
		}
	});

	let animate = gsap.utils.toArray('.animate')
	animate.forEach((item, index) => {
		let tl = gsap.timeline({
		scrollTrigger: {
		trigger: item,
		once: true,
		start: triggerPosition,
		toggleActions:"restart none none reset",
		end: "500",
		markers: false,
		toggleClass: 'animateelement'
		}
		});
	});

})
